<template>
  <div class="tags_content">
    <section
      class="display_flex align_item_center justify_content_space_between page_section_card"
    >
      <div>
        <el-popover
          popper-class="setClassifyPopover"
          placement="bottom"
          v-model="visible"
          trigger="manual"
        >
          <el-checkbox-group v-model="categories">
            <el-checkbox
              v-for="(item, index) in allClassify"
              :key="index"
              :label="item.category"
            ></el-checkbox>
          </el-checkbox-group>
          <div class="btns">
            <el-button @click="visible = false;">取消</el-button>
            <el-button type="primary" @click="setClassifyHandle"
              >确定</el-button
            >
          </div>
          <el-button type="primary" @click="setClassify()" slot="reference">批量设置分类</el-button>
        </el-popover>
      </div>
      <el-input
        style="width: 300px"
        placeholder="请输入标签名称"
        v-model="postdata.category"
        class="input-with-select"
        clearable
        @keyup.enter.native="search"
      >
        <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
      </el-input>
    </section>
    <el-alert
      class="page_section_card"
      :title="`共有${total}个标签`"
      type="success"
      :closable="false"
      show-icon
    >
    </el-alert>
    <el-table
      v-loading="loading"
      :data="list"
      stripe
      style="width: 100%"
      tooltip-effect="dark"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="category" label="标签名称" min-width="180">
      </el-table-column>
      <el-table-column prop="p_category" label="所属分类" min-width="180">
        <template slot-scope="scope">
          {{
            scope.row.p_category && scope.row.p_category.length > 0
              ? scope.row.p_category.join("，")
              : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100px">
        <template slot-scope="scope">
          <el-popover
            ref="popover"
            popper-class="setClassifyPopover"
            placement="left"
            v-model="scope.row.visible"
            trigger="manual"
          >
            <el-checkbox-group v-model="categories">
              <el-checkbox
                v-for="(item, index) in allClassify"
                :key="index"
                :label="item.category"
              ></el-checkbox>
            </el-checkbox-group>
            <div class="btns">
              <el-button @click="closeItemPopover(scope.$index)">取消</el-button>
              <el-button type="primary" @click="setClassifyHandle"
                >确定</el-button
              >
            </div>
            <el-button
              type="text"
              slot="reference"
              @click="setClassify(scope.row, scope.$index)"
              >设置分类</el-button>
          </el-popover>
          <!-- <el-button
            type="text"
            v-popover:popover
            @click="setClassify(scope.row, scope.$index)"
            title="设置分类"
            ><i class="icon_ el-icon-folder-add"></i
          ></el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <pagination-vue
      layoutConfig="prev, pager, next, jumper"
      @sizeChange="sizeInit"
      @currentChange="init"
      :pageSize="postdata.page_num"
      :total="total"
      :firstPage="firstPage"
    />
  </div>
</template>
<script>
import paginationVue from "@/components/paginationVue.vue";
export default {
  components: {
    paginationVue,
  },
  data() {
    return {
      loading: false,
      visible: false,
      postdata: {
        category: "",
        page: 1,
        page_num: 10,
      },
      firstPage: 1,
      total: 0,
      list: [],
      categories: [],
      tags: [],
      allClassify: [],
      type_: 1,
    };
  },
  created() {
    this.init(1);
  },
  methods: {
    closeItemPopover(index) {
      this.$set(this.list[index], "visible", false);
      this.categories = [];
      this.tags = [];
    },
    setClassify(row, index) {
      this.getAllClassify();
      if (row) {
        this.type_ = 2;
        this.tags[0] = row.category;
        this.list.forEach((item, i) => {
          if (index === i) {
            this.$set(this.list[index], "visible", true);
          } else {
            this.$set(this.list[i], "visible", false);
          }
        });
        this.categories = row.p_category || [];
      } else {
        if (this.tags.length < 1) {
          this.visible = false;
          this.categories = [];
          this.$message({
            message: "请先选择标签",
            type: "warning",
          });
        } else {
          this.type_ = 1;
          this.visible = true;
        }
      }
      this.$forceUpdate();
    },
    setClassifyHandle() {
      if (this.tags.length < 1) {
        this.$message({
          message: "请选择分类",
          type: "warning",
        });
        return;
      }
      this.axios("POST", `${this.$baseUrl}v1/categoryFb/setStore`, {
        tabs: this.tags,
        categories: this.categories,
        type: this.type_
      }).then((res) => {
        if (res.code == 1) {
          this.visible = false;
          this.init(this.firstPage);
          this.categories = [];
          this.$forceUpdate();
        }
      });
    },
    search() {
      this.init(1);
    },
    handleSelectionChange(val) {
      this.tags = val.map(i => {
        return i.category;
      });
    },
    sizeInit(size) {
      this.postdata.page_num = size || 10;
      this.init(1);
    },
    init(page) {
      this.postdata.page = page || this.firstPage;
      this.loading = true;
      this.axios(
        "get",
        `${this.$baseUrl}v1/categoryFb/lists`,
        this.postdata
      ).then((res) => {
        this.loading = false;
        if (res.code == 1) {
          this.list = res.data.data.map(item=> {
            item.visible = false;
            return item;
          });
          this.total = res.data.total;
          this.firstPage = res.data.current_page;
        }
      });
    },
    getAllClassify() {
      this.axios("get", `${this.$baseUrl}v1/categoryFb/topLists`, {
        page: 1,
        page_num: 100,
      }).then((res) => {
        if (res.code == 1) {
          this.allClassify = res.data.data;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.tags_content {
  width: calc(100% - 310px);
  padding-left: 20px;
}
</style>