<template>
  <div class="classify_content">
    <el-button
      type="primary"
      size="medium"
      class="add_btn"
      @click="dialogVisible = true"
      >新增分类</el-button
    >
    <el-input
      placeholder="请输入分类名称"
      v-model="postdata.category"
      class="input-with-select"
      clearable
      @keyup.enter.native="search"
    >
      <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
    </el-input>
    <div class="infinite-list-wrapper" style="overflow: auto">
      <ul
        class="list"
        v-infinite-scroll="load"
        :infinite-scroll-disabled="disabled"
      >
        <li
          v-for="(i, key) in list"
          :key="key"
          class="
            list-item
            display_flex
            align_item_center
            justify_content_space_between
          "
        >
          <span>{{ i.category }}</span>
          <!-- <el-button-group>
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-edit"
            ></el-button>
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
            ></el-button>
          </el-button-group> -->
          <div class="btn_group">
            <i class="el-icon-edit" title="编辑" @click="editClassify(i)"></i>
            <el-popconfirm
              title="确定删除该分类吗？"
              confirm-button-type="text"
              @confirm="delClassify(i)"
            >
              <i class="el-icon-delete" slot="reference" title="删除"></i>
            </el-popconfirm>
          </div>
        </li>
      </ul>
      <p v-if="loading">
        <el-divider
          ><i style="color: #909399; font-size: 12px">加载中...</i></el-divider
        >
      </p>
      <p v-if="noMore">
        <el-divider
          ><i style="color: #909399; font-size: 12px">没有更多了</i></el-divider
        >
      </p>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="500px"
      :title="`${addData.id ? '编辑分类' : '新增分类'}`"
    >
      <section>
        <el-form
          :model="addData"
          ref="form1"
          label-width="100px"
          @submit.native.prevent
          @keyup.enter.native="addClassify"
        >
          <el-form-item
            prop="category"
            label="分类名称："
            :rules="[{ required: true, message: '请输入分类名称' }]"
          >
            <el-input
              type="text"
              v-model="addData.category"
              placeholder="请输入分类名称"
            ></el-input>
          </el-form-item>
        </el-form>
      </section>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addClassify">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      list: [],
      loading: false,
      postdata: {
        category: "",
        page: 1,
        page_num: 20,
      },
      last_page: 2,
      addData: {
        category: "",
      },
    };
  },
  computed: {
    noMore() {
      return this.postdata.page > this.last_page;
    },
    disabled() {
      return this.loading || this.noMore;
    },
  },
  methods: {
    delClassify(row) {
      console.log(row);
      this.axios("POST", `${this.$baseUrl}v1/categoryFb/del`, {
        id: row.id,
      }).then((res) => {
        if (res.code == 1) {
          this.search();
        }
      });
    },
    editClassify(row) {
      this.dialogVisible = true;
      this.addData = row;
    },
    addClassify() {
      this.$refs["form1"].validate((valid) => {
        if (valid) {
          let url = `${this.$baseUrl}v1/categoryFb/addStore`;
          if (this.addData.id) {
            url = `${this.$baseUrl}v1/categoryFb/editStore`;
          }
          this.axios("POST", url, this.addData).then((res) => {
            if (res.code == 1) {
              this.dialogVisible = false;
              this.addData = {
                category: "",
              };
              this.search();
            }
          });
        } else {
          return false;
        }
      });
    },
    search() {
      this.postdata.page = 1;
      this.load();
    },
    load() {
      if (this.postdata.page > this.last_page) {
        return;
      }
      this.loading = true;
      this.axios(
        "get",
        `${this.$baseUrl}v1/categoryFb/topLists`,
        this.postdata
      ).then((res) => {
        this.loading = false;
        if (res.code == 1) {
          if (res.data.current_page === 1) {
            this.list = res.data.data;
          } else {
            this.list = this.list.concat(res.data.data);
          }
          this.last_page = res.data.last_page;
          this.postdata.page++;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.classify_content {
  flex: 0 0 250px;
  padding-right: 20px;
  .add_btn {
    width: 100%;
    margin-bottom: 20px;
  }
  .infinite-list-wrapper {
    margin-top: 20px;
    width: 100%;
    height: 668px;
    .list {
      width: 100%;
      .list-item {
        color: #303133;
        padding: 10px 10px;
        transition: all 0.3ms;
        span {
          font-size: 14px;
        }
        .btn_group {
          i {
            margin-left: 10px;
            font-weight: bold;
            cursor: pointer;
            font-size: 16px;
            &.el-icon-edit {
              color: #409eff;
            }
            &.el-icon-delete {
              color: #f56c6c;
            }
          }
        }
        &:hover {
          background: #ebeef5;
          border-radius: 4px;
        }
      }
    }
  }
}
</style>