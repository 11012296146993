<template>
  <div class="main_content">
    <el-page-header
      class="page_section_card"
      @back="goBack"
      content="Facebook分类设置"
    ></el-page-header>
    <el-card>
      <section class="display_flex" style="width: 100%">
        <fb-classify-comp></fb-classify-comp>
        <el-divider direction="vertical"></el-divider>
        <tags-comp></tags-comp>
      </section>
    </el-card>
  </div>
</template>
<script>
import fbClassifyComp from "../classifySetting/comps/fbClassifyComp.vue";
import tagsComp from "../classifySetting/comps/tagsComp.vue";
export default {
  components: {
    fbClassifyComp,
    tagsComp,
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  }
}
</script>
<style lang="scss" scoped>
.main_content {
  .el-divider--vertical {
    min-height: 770px;
  }
}
</style>